<template>
    <div class="col-4 col-xs-12 col-md-4">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <ExportTable
                        :has-add-function="true"
                        :cols-to-delete="colsToDelete"
                        class="mt-0 mr-0"
                        file-name="incoterms"
                        table-id="incoTermsTable"
                        style="padding-top: 2px !important;"
                        @add-action="editDialog = true"
                    ></ExportTable>
                    <HelpPopUpV2 help-page-component="CountriesListing" :no-padding="true"></HelpPopUpV2>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.incoTerms"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :height="tableHeight"
            :items="allIncoTerms"
            :items-per-page="-1"
            :search="searchTerm"
            :options.sync="tableOptions"
            :sort-by="'Incoterm.title'"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            fixed-header
            id="incoTermsTable"
            item-key="Incoterm.id"
        >
            <template v-slot:item.Incoterm.title="{ item }">
                <span class="font-weight-bold">{{ item.Incoterm.title }}</span>
            </template>
            <template v-slot:item.Incoterm.id="{ item }">
                <div class="text-center">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="openEditIncoTermDialog(item.Incoterm.id, item.Incoterm.title)">
                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateIncoTerm') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="deleteIncoTerm(item.Incoterm.id, item.Incoterm.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteIncoTerm') }}</v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </v-data-table>
        <AddIncoTerm
            :inco-term-id="selectedIncoTermId"
            :inco-term="selectedIncoTerm"
            :dialog.sync="editDialog"
            :update-mode="updateMode"
            @dialog-closed="editDialogClosed"
            @update-done="incoTermUpdated"
            @create-done="incoTermCreated"
        ></AddIncoTerm>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapActions} from "vuex";

const ExportTable = () => import("Components/Appic/ExportTable");
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");
const AddIncoTerm = () => import("Components/Appic/AddIncoTerm");

export default {
    name: "IncoTermsList",
    components: {AddIncoTerm, HelpPopUpV2, ExportTable },
    props:  ['addDialog','reload'],
    data() {
        return {
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: null,
            hideDefaultFooter: true,
            loading: {
                fetch: false,
                filterResults: false,
                incoTerms: false
            },
            searchField: null,
            searchTerm: null,
            selectedIncoTermId: null,
            selectedIncoTerm: null,
            tableHeight: '500',
            tableOptions: {
                page: 1
            },
            totalIncoTerms: 0,
            updateMode: false
        }
    },
    computed: {
        ...mapFields('incoterm', {
            allIncoTerms: 'allIncoTerms'
        }),
        headers () {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'Incoterm.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.name'),
                    value: 'Incoterm.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                }
            ]
        },
    },
    methods: {
        ...mapActions('incoterm', {
            deleteIncoTermById: 'deleteIncoTermById',
            getAllIncoTerms: 'getAllIncoTerms',
            resetState: 'resetState'
        }),
        incoTermCreated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
        incoTermUpdated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
        async deleteIncoTerm(val, title) {
            if(await this.$root.$confirm(this.$t('message.deleteIncoTerm') + ' ' +  title, this.$t('message.confirmations.continueIncoTermCancelAction'), {color: 'orange'})){
                this.deleteIncoTermById(val).then((status) => {
                    if(status == 'done'){
                        this.$toast.success(this.$t('message.successes.incoTermDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.resetState()
                        this.getAllIncoTerms()
                    } else {
                        this.$toast.error(this.$t('message.errors.incoTermNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            if(this.updateMode == false){
                this.$emit('add-dialog-closed')
            }
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (285);
        },
        openCreateIncoTermDialog () {
            this.updateMode = false
            this.editDialog = true
        },
        openEditIncoTermDialog (incoTermId, incoTerm) {
            this.selectedIncoTermId = incoTermId
            this.selectedIncoTerm = incoTerm
            this.updateMode = true
            this.editDialog = true
        },
    },
    watch: {
        addDialog(value) {
            if(value) {
                this.openCreateIncoTermDialog()
            }
        }
    },
    created() {
        this.resetState()
        if(this.allIncoTerms.length == 0) this.getAllIncoTerms()
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>